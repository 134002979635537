import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  Output,
  EventEmitter,
  Input, ElementRef
} from '@angular/core';
import {Tile} from "../tile/tile.component";
import {GameOptions, newTile} from "../game.component";
import * as _ from "lodash";

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css','../game.component.css']
})
export class BoardComponent implements OnInit {

  @Input()
  gameWidth : number;

  @Input()
  options: GameOptions;

  adds: newTile[];

  get style() {
    return { width: `${this.gameWidth}px`, 'min-height': `${this.gameWidth}px`}
  }

  get rowStyle() {
    return {width: `${this.gameWidth}px`, 'min-height': `${this.gameWidth/this.height}px`}
  }

  get fieldStyle() {
    return {width: `${this.gameWidth/this.width}px`, 'min-height': `${this.gameWidth/this.height-10}px`}
  }

  @Output()
  consolidations = new EventEmitter<number>();

  @Output()
  started = new EventEmitter<void>();

  @Output()
  moved = new EventEmitter<{key: string, source: string}>();

  @Output()
  added = new EventEmitter<Tile>();

  width = 5;
  height = 5;
  fourThreshold = 0.5;
  newTiles = 2;
  aidLuck = false;
  over = true;
  counter = 0;
  interpolation = 1;

  fields: Tile[][] = [];
  tiles = [];

  busy = false;

  hintText: string;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  keyCount = { ArrowDown : 0, ArrowUp: 0, ArrowLeft: 0, ArrowRight : 0};
  animation?: Promise<void>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public element: ElementRef
  ) {
    this.save = _.throttle(this.save.bind(this), 2000);
    this.hint = "try the autopilot below the board";
  }

  ngOnInit(): void {
    this.restart(true);
  }

  set hint(text: string | null) {
    this.hintText = text;
    if(text) {
      setTimeout(() => this.hintText = null, 5000);
    }
  }

  restart(first = false) {
    this.over = false;
    this.tiles.length = 0;
    this.width = this.options.width;
    this.aidLuck = this.options.aidLuck;
    this.fourThreshold = this.options.fourThreshold;
    this.newTiles = this.options.newTiles;
    this.keyCount = { ArrowDown : 0, ArrowUp: 0, ArrowLeft: 0, ArrowRight : 0};


    this.height = 2 * this.width / 2; //this.options.height;
    this.fields.length = this.height;

    for(let row =0; row < this.height; row ++) {
      this.fields[row] = new Array(this.width).fill(null);
    }

    // for(let i = 0;i < this.width*this.height; i++) {
    //   let x = i%this.width, y = (i-x)/this.width;
    //   if(y%2) x = this.width - x -1;
    //   const tile = new Tile(Math.pow(2,i?i:1)*2,x,y, 0);
    //   this.addTile(tile);
    //
    // }
    if(!first ||!this.load()) {
      this.started.emit();
      this.addValues(1, 0);
    }
  }

  value(x: number, y: number) {
    if(this.inRange(x,y)) {
      return this.fields[y][x]?.value;
    }
  }

  addValue(mx: number, my: number) {
    const limit = mx ? this.height : this.width;
    const di = Math.floor(limit * Math.random());
    for(let i = 0; i < limit; i ++) {
      const fi = (i+di) % limit;
      const x = mx ? (mx > 0 ? 0 : this.width - 1) : fi;
      const y = mx ? fi : (my > 0 ? 0 : this.height - 1);
      const tile = this.fields[y][x];
      if (!tile) {
        let newValue = Math.random() > this.fourThreshold ? 4 : 2;
        if(this.aidLuck && this.tiles.length == this.width*this.height-1) {
          const neighbours = [
            this.value(x-1,y),
            this.value(x+1,y),
            this.value(x,y-1),
            this.value(x,y+1)]
            .filter(v=>!!v).sort((a,b)=>Math.sign(a-b));
          if(neighbours[0] <= 4) {
            newValue = neighbours[0];
            console.log('neighbours', neighbours);
          }
        }
        this.addTile(new Tile(newValue, x, y, this.counter));
        return true;
      }
    }
    return false;
  }

  addTile(tile: Tile) {
    this.tiles.push(tile);
    this.fields[tile.y][tile.x] = tile;
    this.added.emit(tile);
  }

  addValues(dx: number, dy: number) {
    if(this.options.newTiles) {
      const result = this.addValue(dx, dy);
      for (let i = 1; i < this.options.newTiles; i++) {
        this.addValue(dx, dy);
      }
      return result;
    } else if(this.adds) {
      for(let add of this.adds) {
        this.addTile(new Tile(add.v, add.x, add.y, this.counter))
      }
    }
    return true;
  }

  inRange(x,y) {
    return (x >= 0 && y >= 0 && x < this.width && y < this.height);
  }

  moveStep(dx: number, dy: number) {
    let moved = false;
    this.loopAll(dx, dy,
      null,
      (x,y) => {
        const tile = this.fields[y][x];
        if(tile) {
          if (this.inRange(x + dx, y + dy)) {
            const current = this.fields[y + dy][x + dx];
            if (!current) {
              this.fields[y + dy][x + dx] = tile;
              tile.x = x + dx;
              tile.y = y + dy;
              this.fields[y][x] = null;
              moved = true;
            }
          }
        }
    });
    return moved;
  }

  loopRows(dy:number, cb: (y:number) => void) {
    for(let y = (dy >0 ? this.height-1: 0); y != (dy > 0 ? -1 : this.height ); y += (dy > 0 ? -1: 1)) {
      cb(y);
    }
  }
  loopCols(dx:number, cb: (x:number) => void) {
    for(let x = (dx >0 ? this.width-1: 0); x != (dx > 0 ? -1 : this.width ); x += (dx > 0 ? -1: 1)) {
      cb(x);
    }
  }

  loopAll(dx: number, dy:number, rowCb: (y:number) => void, fieldCB:(x: number, y:number) => void) {
    this.loopRows(dy, (y) => {
      if(rowCb) {
        rowCb(y);
      }
      this.loopCols(dx, (x) => {
        fieldCB(x, y);
      })
    })
  }
  consolidate(dx: number, dy: number, dryRun: boolean = false) {
    const done = [];
    let count = 0;
    this.loopAll(dx, dy,
      (y) => done[y] = new Array(this.width).fill(false),
      (x,y) => {
        const tile = this.fields[y][x];
        if(tile) {
          if (this.inRange(x + dx, y + dy)) {
            const current = this.fields[y + dy][x + dx];
            if (current && current.value == tile.value && !done[y][x]) {
              if(!dryRun) {
                const idx = this.tiles.findIndex(t => t == current);
                this.tiles.splice(idx, 1);
                tile.value *= 2;
                tile.x += dx;
                tile.y += dy;
                tile.pts += current.pts + tile.value;
                this.fields[y + dy][x + dx] = tile;
                this.fields[y][x] = null;
                done[y+dy][x+dx] = true;
                this.consolidations.emit(tile.value);
              }
              count ++;
            }
          }
        }
      });
    return count;
  }

  checkGameOver() {
    if(!this.fields.some(row => row.some( field => !field))) {
      let worked = false;
      worked = worked || this.consolidate(-1, 0, true) > 0;
      worked = worked || this.consolidate(1, 0, true) > 0;
      worked = worked || this.consolidate(0, 1, true) > 0;
      worked = worked || this.consolidate(0, -1, true) > 0;
      return !worked;
    }
    return false;
  }

  move(dx: number, dy: number) {
    if (this.busy) return;

    this.counter ++;
    let moved=false;
    for(let i = 0; i < (dx ? this.width: this.height)-1; i ++) {
      moved = this.moveStep(dx, dy) || moved;
    }
    moved = this.consolidate(dx, dy) > 0 || moved;
    moved = this.moveStep(dx, dy) || moved;
    if(moved) {
      this.addValues(dx, dy);
    }
    if(this.checkGameOver()) {
      this.over = true;
      console.error('GAME OVER');
    } else {
      for (let i = 0; i < (dx ? this.width : this.height) - 1; i++) {
        this.moveStep(dx, dy);
      }
    }
    if(moved) {
      this.save();
      this.analyzeHollows();
    }

    return moved;
  }

  handleKey(key:string, source: string) {
    if(this.keyCount.hasOwnProperty(key)) {
      this.moved.emit({key, source});
    }
    let result = false;
    switch(key) {
      case 'ArrowUp':
        result = this.move(0,-1);
        break;
      case 'ArrowDown':
        result = this.move(0,1);
        break;
      case 'ArrowRight':
        result = this.move(1,0);
        break;
      case 'ArrowLeft':
        result = this.move(-1,0);
        break;
      case 'Enter':
        if(this.over) {
          this.restart();
        }
        break;
    }
    if(this.keyCount.hasOwnProperty(key)) {
      this.keyCount[key] ++;
      // console.log(this.keyCount);
      this.animation = this.animate();
      return result;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.handleKey(event.key,'keyboard');
    if(this.keyCount.hasOwnProperty(event.key)) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  swipe(e: TouchEvent, action: 'start'|'end') {
    console.log(`swipe ${action}`, e);
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();
    if(action === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1500) {
        if ( Math.abs(direction[0]) > 30 // Long enough
          && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
        { // Horizontal enough
          const swipe = direction[0] < 0 ? 'ArrowLeft' : 'ArrowRight';
          this.handleKey(swipe,'swipe');
          if(this.keyCount.hasOwnProperty(swipe)) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
        if ( Math.abs(direction[1]) > 30 // Long enough
          && Math.abs(direction[1]) > Math.abs(direction[0] * 3))
        { // Horizontal enough
          const swipe = direction[1] < 0 ? 'ArrowUp' : 'ArrowDown';
          this.handleKey(swipe,'swipe');
          if(this.keyCount.hasOwnProperty(swipe)) {
            e.preventDefault();
            e.stopPropagation();
          }
        }
      }

    }
  }
  async sleep() {
    await new Promise( resolve => setTimeout(resolve, this.options.animationDelay) )
  }

  async animate() {
    if(this.busy) return;
    this.busy = true;
    const steps = this.options.animationSteps;
    for(let i = 0; i < steps; i ++) {
      if(i) {
        await this.sleep();
      }
      this.interpolation = (1+i)/steps;
      if(i) {
        this.changeDetectorRef.detectChanges();
      }
    }
    // need to give UI a chance anyway (in autopilot)
    if(steps == 1) {
      await this.sleep();
    }
    this.tiles.forEach(tile => tile.endAnim());
    this.busy = false;
  }

  get fillRate() {
    return this.tiles.length / (this.width * this.height)
  }

  save() {
    if(this.over) return;
    localStorage.setItem('currentGame', JSON.stringify(this.fields));
  }

  load() {
    const saved = JSON.parse(localStorage.getItem('currentGame')||'[]');
    if(this.fields.length == saved.length && this.fields[0].length == saved[0].length ) {
      this.tiles.length = 0;
      saved.forEach( (row,y) => row.forEach((tile, x) => {
        if(tile) {
          const newTile = new Tile(tile.val, tile.x, tile.y, -1);
          newTile.pts = tile.pts;
          this.fields[y][x] = newTile;
          this.tiles.push(newTile);
        } else {
          this.fields[y][x] = null;
        }
      }));
      this.analyzeHollows();
      return true;
    }
  }

  analyzeHollows() {
    for(let y = 0; y < this.height; y++) {
      for(let x = 0; x < this.width; x++) {
        const tile = this.fields[y][x];
        if(!tile) { continue }
        const log = Math.log2(tile.value);
        const north = this.inRange(x,y-1) ? Math.log2(this.fields[y-1][x]?.value) - log : 1;
        const south = this.inRange(x,y+1) ? Math.log2(this.fields[y+1][x]?.value) - log : 1;
        const east = this.inRange(x+1,y) ? Math.log2(this.fields[y][x+1]?.value) - log : 1;
        const west = this.inRange(x-1,y) ? Math.log2(this.fields[y][x-1]?.value) - log : 1;
        const minDiff = Math.min(north, south, east, west);
        tile.warning = tile.danger = false;
        tile.warning = minDiff >= 1;
        tile.danger = minDiff >= 2;
      }
    }
  }
}
