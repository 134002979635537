<div class="info component" [style]="style">
  <div fxLayout="row">
    <div>Fill: {{(fillRate * 100).toFixed(0)}}% </div>
    <div *ngIf="fillRate==1">Consolidations: {{consolidateCount}}</div>
    <div>Started: {{started.toLocaleString("en-US")}}</div>
    <div>2's rate: {{(twosRate*100).toFixed(0)}}% </div>
  </div>
  <div fxLayout="row">
    <div fxLayout="row" style="height: 32px">
      <div>Max:</div>
      <div style="position: relative" [style]="tileStyle">
        <app-tile [gameWidth]="gameWidth/2" [tilesInRow]="10" [tile]="maxTile" matTooltip="maximum theoretically possible"></app-tile>
      </div>
    </div>
    <div>Max points: {{maxPoints}}</div>
    <div>Moves to max: {{movesToMax}}</div>
  </div>
  <div fxLayout="row">
    <div>Moves: {{moves}}</div>
    <div *ngIf="swipes">Swipes: {{swipes}}</div>
    <div *ngIf="keys">Keys: {{keys}}</div>
  </div>
  <div fxLayout="row wrap">
    <div *ngFor="let vc of valueCount" fxLayout="row">
      <div style="position: relative" [style]="tileStyle">
        <app-tile [gameWidth]="gameWidth/2" [tilesInRow]="10" [tile]="vc[0]"></app-tile>
      </div>
      <div>x{{vc[1]}} ~ {{pointsForTile(vc[0].value)}}pts</div>
    </div>
  </div>
</div>
