<div class="component board"
     (touchstart)="swipe($event, 'start')"
     (touchend)="swipe($event, 'end')"
     [style]="style"
  >
  <div id="fields" class="fields" [style]="style">
      <div *ngFor="let row of fields" class="field-row" fxLayout="row" [style]="rowStyle">
        <div class="field" *ngFor="let _ of row" [style]="fieldStyle" [title]="_||'empty'"></div>
      </div>
      <app-tile *ngFor="let tile of tiles" [tile]="tile"
                [gameWidth]="gameWidth" [counter]="counter" [tilesInRow]="width"
                [interpolation]="interpolation"></app-tile>
  </div>
  <div *ngIf="over" class="over" fxLayout="column">
    <span>Game over</span>
    <button mat-raised-button (click)="restart()" matTooltip="you can hit ENTER">try again</button>
  </div>
  <div *ngIf="hintText" class="hint">
    <span>{{hintText}}</span>
  </div>
</div>
