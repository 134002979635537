import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {BoardComponent} from "./board/board.component";
import {ScoreComponent} from "./score/score.component";
import * as _ from 'lodash';
import htmlToImage from 'html-to-image';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Subscription} from "rxjs/internal/Subscription";

export class GameAction {
  action: 'autopilot'|'restart'|'stop'|'reload'|'capture'|'replay';
  fill?: number;
  keys?: string[];
  mod?: number;
}

export class GameOptions {
  fourThreshold = 0.95;
  animationSteps = 7;
  animationDelay = 10;
  autopilotFill = 0.9;
  newTiles = 2;
  width = 5;
  aidLuck = true;
}

export class newTile {
  constructor(public v: number, public x: number, public y: number) {}
}

class playTurn {
  tiles: newTile[] = [];
  constructor(public key: string) {}
}

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit, OnDestroy {
  @ViewChild(BoardComponent)
  board: BoardComponent;

  @ViewChild(ScoreComponent)
  score: ScoreComponent;

  innerWidth = 800;
  innerHeight = 600;

  options = new GameOptions();

  autoPilotStop = false;
  autoPilotRunning = false;
  private lastAP: GameAction;
  screenUrl: string = '';
  urlQuery : any = null;
  private subscriptions: Subscription[];

  replay: playTurn[];

  get gameWidth() {
    return Math.min(Math.min(1024, this.innerWidth * 0.8), this.innerHeight*0.7);
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) {
    Object.assign(this.options, JSON.parse(localStorage.getItem('options')||'{}'));
    this.onOptionsChange = _.throttle(this.onOptionsChange.bind(this), 500);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    setTimeout(()=> {
      this.subscriptions = [this.activatedRoute.queryParamMap.subscribe((pars)=> {
        this.parseQueryParams(pars);
      }), this.board.started.subscribe(() => {
        this.subscribeReplay();
      })];
    }, 100);
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(s=>s.unsubscribe());
  }

  subscribeReplay() {
    if(this.replay) return;
    this.replay = [new playTurn('start')];
    const replay = localStorage.getItem('replay');
    if(replay) {
      this.replay = JSON.parse(replay);
    }
    this.subscriptions.push( this.board.started.subscribe(() => {
      // if(this.replay.length > 1 || this.replay[0]?.key!='start') {
      this.replay = [new playTurn('start')];
      // }
      this.saveReplay();
      console.log('restart');
    }));
    this.subscriptions.push( this.board.moved.subscribe(({key}) => {
      this.replay.push(new playTurn(key));
      this.saveReplay();
      console.log('move');
    }));
    this.subscriptions.push( this.board.added.subscribe((tile) => {
      let back = this.replay[this.replay.length-1];
      if(!back) {
        back = new playTurn('start');
        this.replay.push(back);
      }
      back.tiles.push(new newTile(tile.value, tile.x, tile.y));
      this.saveReplay();
      console.log('added');
    }));
    this.saveReplay = _.throttle(this.saveReplay, 500);
  }

  saveReplay() {
    localStorage.setItem('replay', JSON.stringify(this.replay));
  }

  async startReplay() {
    const saveNewTiles = this.options.newTiles;
    this.options.newTiles = 0;
    const turns = this.replay;
    this.replay = [];
    for(let turn of turns) {
      this.board.adds = turn.tiles;
      if(turn.key == 'start') {
        this.board.restart();
      } else {
        this.board.handleKey(turn.key,'replay');
      }
      await this.board.animation;
    }
    this.options.newTiles = saveNewTiles;
  }

  get query() {
    return {
      s: this.board?.width || this.options.width,
      t: this.options.newTiles,
      r: this.options.fourThreshold,
      a: this.options.aidLuck,
    }
  }

  onOptionsChange() {
    localStorage.setItem('options', JSON.stringify(this.options));
    if((this.board.width != this.options.width ||
      this.board.newTiles != this.options.newTiles ||
      this.board.fourThreshold != this.options.fourThreshold)
      && this.score.total
    ) {
      this.board.hint = "restart the game in order to apply board size change";
    }
    const url = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: this.query
    }).toString();

    this.location.go(url);
    this.score.load();
    if(!this.score.total) {
      this.board.restart();
    }
  }

  parseQueryParams(pars:ParamMap) {
    if(!pars.keys.length) return;
    this.urlQuery = pars;
    let haveS = false;
    if(pars.has('s')) {
      const value = parseInt(pars.get('s'));
      if(value >= 2 && value <= 6) {
        this.options.width = value;
        haveS = true;
      }
    }
    if(pars.has('t')) {
      const value = Number.parseInt(pars.get('t'));
      if(value >= 1 && value <= (haveS || !this.board ? this.options.width : this.board.width)) {
        this.options.newTiles = value;
      }
    }
    if(pars.has('r')) {
      const value = Number.parseFloat(pars.get('r'));
      if(value >= 0.5 && value <= 1) {
        this.options.fourThreshold = Number.parseFloat(value.toFixed(1));
      }
    }
    if(pars.has('a')) {
      const value = pars.get('a')=='true';
      this.options.aidLuck = value;
    }
    if(haveS) {
      if(this.board.width != this.options.width) {
        this.board.restart();
      }
    }
    this.onOptionsChange();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }

  async autopilot(e: GameAction) {
    if(this.autoPilotRunning) return;
    this.autoPilotRunning = true;
    this.autoPilotStop = false;
    const mostUsed = Object.entries(this.board.keyCount)
      .sort((a, b) => Math.sign(b[1] - a[1]));
    // console.log(mostUsed);
    let step = 0;
    let noMove = 0;
    while(this.board.fillRate < e.fill && !this.autoPilotStop && !this.board.over) {
      const key = e.keys
        ? e.keys[step % e.mod]
        : mostUsed[step % e.mod][0];
      let moved = this.board.handleKey(key,'autopilot');
      await this.board.animation;
      if(!moved) {
        noMove++;
        if(this.board.over) break;
      } else {
        noMove = 0;
      }
      if(noMove >= 2 ) {
        console.log(`autopilot stalled at fill rate ${this.board.fillRate*100}%, de-blocking using third key`);
        const key2 = e.keys ? e.keys[e.mod % e.keys.length] : mostUsed[e.mod % mostUsed.length][0];
        let moved = this.board.handleKey(key2,'autopilot');
        await this.board.animation;
        if(!moved) {
          break;
        } else {
          noMove = 0;
        }
      }
      step++;
    }
    this.autoPilotRunning = false;
    if(this.autoPilotStop) {
      this.board.hint = "auto pilot stop requested";
    } else if(noMove >=2) {
      this.board.hint = "auto pilot stopped, no more moves (unless left)";
    } else {
      this.board.hint = "auto pilot ended, board is full enough";
    }
    console.log(`autopilot ended fill rate ${this.board.fillRate*100}%`);
  }

  async action(e:GameAction) {
    console.log('action', e.action);
    switch(e.action) {
      case 'autopilot':
        this.lastAP = e;
        this.autopilot(e).catch();
        break;
      case 'restart':
        this.board.restart();
        break;
      case 'reload':
        this.board.load();
        this.board.over = false;
        break;
      case 'stop':
        if(this.autoPilotRunning) {
          this.autoPilotStop = true;
        } else if(this.lastAP) {
          this.autopilot(this.lastAP).catch();
        }
        break;
      case 'capture':
        await this.capture();
        break;
      case 'replay':
        await this.startReplay();
        break;
    }
  }

  async capture() {
    // const canvas = await html2canvas(document.querySelector("#fields"),
    //   {removeContainer: true, logging: true, foreignObjectRendering: false});
    // const canvas = await htmlToImage.toCanvas(this.board.element.nativeElement);
    const canvas = await htmlToImage.toCanvas(document.querySelector("#fields"),
      {
        backgroundColor: "#404040"
      });
    this.screenUrl = canvas.toDataURL('image/jpg', 90);
    setTimeout(()=> this.screenUrl = '', 10000);
  }

  onStarted() {
    this.score?.reset();
    if(this.urlQuery) {
      const url = this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: this.query
      }).toString();
      this.location.go(url);
    }
  }

  addScore(add: number) {
    this.score?.add(add);
  }

}
