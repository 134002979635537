import { Component } from '@angular/core';

interface ITheme {
  tileBg : string[];
  tileFg : string[];
  boardBg : string;
  fieldBg : string;
  bodyBg: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '8192 game';
}
