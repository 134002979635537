import {Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChildren, QueryList} from '@angular/core';
import {GameAction, GameOptions} from "../game.component";
import {MatSlider} from "@angular/material/slider";

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css','../game.component.css']
})
export class ToolsComponent implements OnInit {

  @Input()
  gameWidth : number;

  @Input()
  options: GameOptions;

  @Input()
  imageURL: string;

  @ViewChildren(MatSlider)
  sliders: QueryList<MatSlider>;

  get style() {
    return { width: `${this.gameWidth}px`}
  }

  @Output()
  action = new EventEmitter<GameAction>();

  @Output()
  optionsChange = new EventEmitter<void>();

  showHelp = false;

  constructor() { }

  ngOnInit(): void {
  }

  onChange() {
    this.options.animationSteps = 7;
    if(this.options.animationDelay < 20) {
      this.options.animationSteps = 5;
    }
    if(this.options.animationDelay < 10) {
      this.options.animationSteps = 3;
    }
    if(this.options.animationDelay < 5) {
      this.options.animationSteps = 1;
    }
    this.sliders.forEach(slider => slider.blur());
    this.options.newTiles = Math.min(this.options.newTiles, this.options.width);
    this.optionsChange.emit();
  }
  autopilot(dir: 'SE'|'NE'|'R') {
    let keys;
    let mod = 2;
    switch(dir) {
      case 'SE':
        keys = ['ArrowRight', 'ArrowDown', 'ArrowUp'];
        break;
      case 'NE':
        keys = ['ArrowRight', 'ArrowUp', 'ArrowDown'];
        break;
      case 'R':
        keys = ['ArrowRight', 'ArrowDown', 'ArrowLeft', 'ArrowUp'];
        mod = 4;
        break;
    }
    this.action.emit({ action: 'autopilot', fill: this.options.autopilotFill, keys, mod });
  }

  restart() {
    this.action.emit({action: 'restart'});
  }

  reload() {
    this.action.emit({action: 'reload'});
  }

  stop() {
    this.action.emit({action: 'stop'});
  }

  replay() {
    this.action.emit({action: 'replay'});
  }

  capture() {
    this.action.emit({action: 'capture'});
  }

  help() {
    this.showHelp = true;
  }

  cancel() {
    this.showHelp = false;
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch(event.key) {
      case 'Backspace':
        this.reload();
        break;
      case 'R':
      case 'r':
        if(!event.ctrlKey) {
          this.restart();
        }
        break;
      case 'S':
      case 's':
        this.autopilot('SE');
        break;
      case 'N':
      case 'n':
        this.autopilot('NE');
        break;
      case ' ':
        this.stop();
        event.preventDefault();
        break;
      case '?':
        this.help();
        break;
      case 'Escape':
        this.cancel();
        break;
      default:
        // console.log(event.key);
    }
  }

}
