import {Component, Input, OnInit} from '@angular/core';
import * as tinycolor from "tinycolor2";

export class Tile {
  px: number;
  py: number;
  pv: number;
  warning = false;
  danger = false;
  pts = 0;

  constructor(
    public val: number ,
    public x: number,
    public y: number,
    public whenAdded = 0)
  {
    this.endAnim();
  }

  get value() { return this.val; }
  set value(val) {
    this.val = val;
  }

  endAnim() {
    this.px = this.x;
    this.py = this.y;
    this.pv = this.val;
  }
}

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css']
})
export class TileComponent implements OnInit {

  @Input()
  gameWidth : number;
  @Input()
  tilesInRow : number;
  @Input()
  useSfx: boolean = true;

  @Input()
  tile? : Tile;

  @Input()
  counter : number = 1;

  @Input()
  interpolation: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

  get value() {
    let num = this.between(this.tile.pv, this.tile.value);
    let suffix='';
    if(this.useSfx) {
      if (num >= 1024) {
        num = num / 1024;
        suffix = 'k';
      }
      if (num >= 1024) {
        num = num / 1024;
        suffix = 'M';
      }
      if (num >= 1024) {
        num = num / 1024;
        suffix = 'G';
      }
    }
    return `${num.toFixed(0)}${suffix}`;
  }

  interpolateStyles(s1: any,s2: any) {
    const result = Object.assign({}, s2);
    const bg = 'background-color';
    if(s2[bg] && s1[bg]) {
      const c1 = tinycolor(s1[bg]).toRgb();
      const c2 = tinycolor(s2[bg]).toRgb();
      c1.r = this.between(c1.r, c2.r);
      c1.g = this.between(c1.g, c2.g);
      c1.b = this.between(c1.b, c2.b);
      result[bg] = `#${tinycolor(c1).toHex()}`;
    }
    return result;
  }

  between(a: number, b: number) : number {
    const i = this.interpolation;
    const p = 3;
    const t = i < 0.5
      ? Math.pow(2 * i, p) / 2
      : 1 - Math.pow(2 * (1 - i), p) / 2;
    return a + t * (b - a);
  }

  get style() {
    const res = this.interpolateStyles(
      this.getStyle(this.tile.pv),
      this.getStyle(this.tile.value));
    const x = this.between(this.tile.px, this.tile.x);
    const y = this.between(this.tile.py, this.tile.y);
    res['top'] =`${5 + y * this.gameWidth/this.tilesInRow}px`;
    res['left'] =`${5 + x * this.gameWidth/this.tilesInRow}px`;
    if(this.tile.whenAdded == this.counter) {
      res.opacity=`${this.between(0,100)}%`;
    }

    Object.assign(res, {
      width: `${this.gameWidth/this.tilesInRow-10}px`,
      'min-height': `${this.gameWidth/this.tilesInRow-10}px`});
    return res;
  }

  getStyle(value: number) {
    if(!value) return {};
    const colors = [
      "pink", "#8e58d7", "#4b3cd7", "#4bc9d7", "#42d75f", "#66aa2b", "#e1bc4e",
      "#aa424b", "#9b131a", "#880e51", "#200085", "#037c83", "#fefefe", '#1d1d1d'
    ];
    const log2 = Math.min(Math.floor(Math.log2(value)), colors.length -1);
    const sizes = this.useSfx ? [40, 40, 25] : [ 40, 40, 30, 20, 15 ];
    const log10 = Math.min(Math.floor(Math.log10(value)), sizes.length -1);
    const res = {
      "background-color": colors[log2],
      "font-size": `${sizes[log10]*this.gameWidth/(this.tilesInRow*60)}px`
    };
    if(value == 1024 || value >= 8192) res["color"] = "white";
    return res;
  }

}
