<div class="tools component" [style]="style">
  <div *ngIf="!showHelp" fxLayout="row">
    <div fxLayout="column">
      <div fxLayout="row">
        <button mat-button (click)="help()" matTooltip="help">
          <span class="material-icons">help</span>
        </button>
        <button mat-button (click)="restart()" matTooltip="restart">
          <span class="material-icons">refresh</span>
        </button>
        <button mat-button (click)="reload()" matTooltip="reload before game over">
          <span class="material-icons">history</span>
        </button>
        <button mat-button (click)="autopilot('SE')" matTooltip="autopilot south-east">
          <span class="material-icons">south_east</span>
        </button>
        <button mat-button (click)="stop()" matTooltip="stop autopilot">
          <span class="material-icons">stop</span>
        </button>
        <button mat-button (click)="autopilot('NE')" matTooltip="autopilot north-east">
          <span class="material-icons">north_east</span>
        </button>
        <button mat-button (click)="autopilot('R')" matTooltip="autopilot round over">
          <span class="material-icons">api</span>
        </button>
        <button mat-button (click)="replay()" matTooltip="replay">
          <span class="material-icons">replay</span>
        </button>
        <button mat-button (click)="capture()" matTooltip="copy board to image">
          <span class="material-icons">screen_share</span>
        </button>
      </div>
      <div fxLayout="row" class="option slider">
        <span>Chance of 2's:&nbsp;</span>
        <mat-slider [min]="0.5" [max]="1" [step]="0.1" thumbLabel matTooltip="what is the change a new tiles it 2 (not 4)"
                    [(ngModel)]="options.fourThreshold" (change)="onChange()"></mat-slider>
      </div>
      <div fxLayout="row" class="option slider" matTooltip="number of new tiles that appear when you move">
        <span>New tiles:&nbsp;</span>
        <mat-slider [min]="1" [max]="options.width" [step]="1" thumbLabel
                    [(ngModel)]="options.newTiles" (change)="onChange()"></mat-slider>
      </div>
      <div fxLayout="row" class="option slider" matTooltip="lower values increase animation speed, 0 does not animate">
        <span>Animation delay:&nbsp;</span>
        <mat-slider [min]="0" [max]="50" [step]="5"
                    [(ngModel)]="options.animationDelay" (change)="onChange()"></mat-slider>
      </div>
      <div fxLayout="row" class="option slider" matTooltip="ratio, when to stop (max is 1), 1.1 never stops">
        <span>Auto pilot mode:&nbsp;</span>
        <mat-slider [min]="0.8" [max]="1.1" [step]="0.1" thumbLabel
                    [(ngModel)]="options.autopilotFill" (change)="onChange()"></mat-slider>
      </div>
      <div fxLayout="row" class="option slider" matTooltip="Will apply on a new game  ">
        <span>Size:&nbsp;</span>
        <mat-slider [min]="2" [max]="6" [step]="1" thumbLabel
                    [(ngModel)]="options.width" (change)="onChange()"></mat-slider>
      </div>
    </div>
    <div *ngIf="imageURL">
      <img class="screen" [src]="imageURL" />
    </div>

  </div>
  <div *ngIf="showHelp">
    <div>
      <button mat-button (click)="cancel()">
        <span class="material-icons">close</span>
      </button>
    </div>
    <h1>How to play</h1>
    <p>use arrow keys (recommended all, but left)</p>
    <p>same values will join when moved in the proper direction</p>
    <p>get the highest number possible</p>
    <h1>Strategy</h1>
    <p>try to keep the biggest on the side</p>
    <p>watch for yellow and red dots, they indicate</p>
    <p>alternating right top, or right bottom will not do much harm</p>
    <h1>Features</h1>
    <p>game state is saved every 2 seconds</p>
    <p>you never win</p>
    <p>game over is not really over - use <span class="material-icons">history</span></p>
    <p>try autopilot buttons
      <span class="material-icons">south_east</span>
      <span class="material-icons">north_east</span>
      <span class="material-icons">stop</span>
    </p>
    <p>the sliders influence the difficulty, or animation</p>
    <h1>Keyboard</h1>
    <p>R - restart</p>
    <p>S - autopilot South East</p>
    <p>N - autopilot North East</p>
    <p>backspace - reload</p>
    <p>space - stop autopilot</p>
    <p>esc - close help</p>
    <p>enter - when game over, it restarts</p>
    <p><span class="material-icons">open_with</span> - move stuff</p>
    <h1>Enjoy the game?</h1>
    <p>You can <a href="https://paypal.me/verliba">donate some euros</a>, to keep me motivated </p>

  </div>
</div>
