import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BoardComponent} from "../board/board.component";
import {Tile} from "../tile/tile.component";
import {Subscription} from "rxjs/internal/Subscription";
import * as _ from 'lodash'

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css','../game.component.css']
})
export class InfoComponent implements OnInit, OnDestroy {

  @Input()
  board: BoardComponent;

  @Input()
  gameWidth : number;

  private subscriptions: Subscription[] = [];

  started: Date = new Date();
  keys: number = 0;
  swipes: number = 0;
  twos = 0;
  fours = 0;

  tiles: {[value: number] : Tile} = {};

  get style() {
    return { width: `${this.gameWidth}px`}
  }

  constructor() {
    this.saveMoves = _.throttle(this.saveMoves, 500);
    this.saveAdds = _.throttle(this.saveAdds, 500);
  }

  ngOnInit(): void {
    this.subscriptions.push(this.board.started.subscribe(()=>{
      this.started = new Date();
      this.keys = 0;
      this.swipes = 0;
      this.twos = 0;
      this.fours = 0;
      localStorage.setItem('gameStarted', this.started.toString());
    }));
    this.subscriptions.push(this.board.moved.subscribe((event)=>{
      const {source} = event;
      switch (source) {
        case 'keyboard': this.keys ++; break;
        case 'swipe': this.swipes ++; break;
      }
      this.saveMoves();
    }));
    this.subscriptions.push(this.board.added.subscribe((tile)=>{
      if(tile.value == 2) {
        this.twos++;
      } else {
        this.fours ++;
      }
      this.saveAdds();
    }));
    const started = localStorage.getItem('gameStarted');
    if(started) {
      this.started = new Date(started);
    }

    const moves = JSON.parse(localStorage.getItem('gameMoves'));
    if(moves) {
      this.keys = moves.moves;
      this.swipes = moves.swipes;
      this.board.keyCount = moves.keys;
    }

    const adds = JSON.parse(localStorage.getItem('addedTiles'));
    if(adds) {
      this.twos = adds.twos;
      this.fours = adds.fours;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s=>s.unsubscribe());
  }

  saveMoves() {
    localStorage.setItem('gameMoves', JSON.stringify({
      keys: this.board.keyCount,
      moves: this.keys,
      swipes: this.swipes
    }));
  }

  saveAdds() {
    localStorage.setItem('addedTiles', JSON.stringify({
      twos: this.twos,
      fours: this.fours,
    }));
  }

  get tileStyle() {
    return {
      width: `${this.gameWidth / 20}px`,
      height: `${this.gameWidth / 20}px`
    }
  }
  get added() { return this.twos + this.fours };

  get twosRate() {
    return this.added ? this.twos / this.added : 0;
  }

  get tileCount() {
    return this.board.tiles.length;
  }

  get fieldCount() {
    return this.board.width * this.board.height;
  }

  get fillRate() {
    return this.tileCount / this.fieldCount
  }

  get moves() {
    return Object.entries(this.board.keyCount)
      .reduce((r,e)=>r+e[1],0)
  }

  get autopilot() {
    return this.moves - this.swipes - this.keys;
  }

  get valueCount() {
    const entries = Object.entries(this.board.tiles.reduce((res, tile) => {
      if(!res.hasOwnProperty(tile.value)) {
        res[tile.value] = 1;
      } else {
        res[tile.value] ++;
      }
      return res;
    }, {}))
    .map(e => [Number.parseInt(e[0]), e[1]])
    .sort((b:[number,number],a:[number,number]) => Math.sign(a[0]-b[0]))
    .map((e:[number,number]) => {
      const tile = this.tiles[e[0]] || new Tile(e[0],0,0,0);
      this.tiles[e[0]] = tile;
      return [tile, e[1]]
    });
    return entries;
  }

  get maxValue() {
    return 2*Math.pow(2, this.board.width*this.board.height);
  }

  get maxTile() {
    const val = this.maxValue;
    if(!this.tiles[val]) {
      this.tiles[val] = new Tile(val,0,0,0);
    }
    return this.tiles[val];
  }

  // pointsForTile2(value:number) {
  //   return value > 2 ? Math.log2(value)*value : 0;
  // }
  // pointsForTile4(value:number) {
  //   return value > 4 ? (Math.log2(value)-2)*value : 0;
  // }
  pointsForTile(value:number) {
    return this.board.tiles
      .filter(t=> t.val == value)
      .reduce((r,t) => r + t.pts, 0);
    // const sq = Math.pow(this.board.fourThreshold , 8);
    // return Math.round(this.pointsForTile2(value) * sq +
    //   (1-sq) * this.pointsForTile4(value));
  }

  get maxPoints() {
    let res = 0;
    let tile = this.maxValue;
    while(tile > 2) {
      res += this.pointsForTile(tile);
      tile /= 2;
    }
    return res;
  }

  get movesToMax() {
    const inTwos = this.maxValue/2;
    return inTwos*(this.board.fourThreshold/2 + 0.5) / this.board.newTiles;
  }

  get consolidateCount()
  {
    return this.board.consolidate(1,0,true)
      + this.board.consolidate(0,1,true)
  }
}
