import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {GameOptions} from "../game.component";

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.css','../game.component.css']
})
export class ScoreComponent implements OnInit {
  total = 0;
  best = 0;

  additions: {v: number, i: number}[] = [];
  increase = 0;

  @Input()
  gameWidth : number;

  @Input()
  options: GameOptions;

  get style() {
    return { width: `${this.gameWidth}px`}
  }

  get key() {
    return `${this.options.width}_${this.options.newTiles}_${this.options.fourThreshold.toFixed(1)}_${this.options.aidLuck?'1':'0'}`;
  }

  constructor() {
    this.saveScore = _.throttle(this.saveScore.bind(this), 500);
    this.animate = _.throttle(this.animate.bind(this), 500);
  }

  ngOnInit(): void {
    this.load()
  }

  reset() {
    this.total = 0;
  }

  additionStyle(a: {v: number, i: number}) {
    return {
      position: "absolute",
      left: `${50+10*a.i}%`,
      top: `-${a.i*20}px`,
      opacity: `${1-a.i}`,
      'font-size': `${(1-a.i)*35}px`
    }
  }

  animate() {
    const add = this.increase;
    this.increase = 0;
    const addition = { v: add, i: 0};
    this.additions.push(addition);
    const moveOn = () => {
      addition .i += 0.1;
      if(addition.i < 1) {
        setTimeout(moveOn, 100);
      } else {
        const index = this.additions.indexOf(addition);
        this.additions.splice(index, 1);
      }
    };
    moveOn();
  }
  add(add: number) {
    this.total += add;
    this.increase += add;
    this.animate();
    this.saveScore();
  }

  saveScore() {
    if(this.total > this.best){
      this.best = this.total;
      localStorage.setItem(`bestScore_${this.key}`, this.best.toString());
    }
    localStorage.setItem(`lastScore_${this.key}`, this.total.toString());
  }

  load() {
    this.best = parseInt(localStorage.getItem(`bestScore_${this.key}`)||'0');
    this.total = parseInt(localStorage.getItem(`lastScore_${this.key}`)||'0');
  }
}
